.ActionBar {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 8px 0 8px;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  pointer-events: none;
}

.ActionBar > * {
  flex: none;
  pointer-events: all;
}

@media all and (min-width: 360px) {
  .ActionBar {
    padding: 0 16px 0 16px;
  }
}

@media all and (min-width: 415px) {
  .ActionBar {
    justify-content: space-between;
    height: 128px;
    flex-flow: column nowrap;
    padding: 0 16px 24px 16px;
    width: 415px;
  }

  .ActionBar .Btn,
  .ActionBar .Btn.rounded {
    border-radius: 1.5rem;
    height: 3rem;
    width: 100%;
    letter-spacing: 2px;
  }
}

@media all and (max-height: 500px) and (orientation: landscape) {
  .ActionBar {
    grid-area: controls;
    align-self: flex-end;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 1rem;
  }

  .ActionBar > :first-child {
    margin: 0 0 1rem 0;
  }
}
