.TrackItem {
  display: grid;
  grid-template-columns: auto minmax(120px, 1fr) auto;
  grid-gap: 1rem;
  align-items: center;
  width: 100%;
}

.TrackItem h2,
.TrackItem p {
  font-size: 0.8125rem;
  margin-bottom: 0;
  user-select: none;
}

.TrackItem h2 {
  color: var(--text);
}

.TrackItem p {
  color: var(--text-secondary);
}

.TrackPlayerContainer {
  height: 3rem;
  width: 3rem;
  border-radius: 8px;
  overflow: hidden;
}

.TrackInformation {
  overflow: hidden;
  color: var(--text);
}

.TrackInformation > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (min-width: 768px) {
  .TrackItem h2,
  .TrackItem p {
    font-size: 1rem;
  }

  .TrackPlayerContainer {
    height: 3.5rem;
    width: 3.5rem;
  }
}
