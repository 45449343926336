.PlayerControl {
  border-radius: 50%;
  /* border: 4px solid white; */
  width: 128px;
  height: 128px;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
}

/* Absolutely positioned element that is centered
 * within parent, regardless of size of bounding box 
 */
.PlayerElement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.PlayerControl:active,
.PlayerControl:focus {
  outline: none;
}

.PlayerAction {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TrackPlayer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.TrackToggle {
  align-self: center;
  margin: auto;
}
