.TrackCardContainer {
  position: relative;
  display: flex;
  grid-area: 1 / 1 / span 1;
}

.TrackCard {
  width: 100%;
  flex: auto;
  border-radius: 32px;
  text-align: center;
  overflow: hidden;
  display: grid;
  grid-template: 1fr / 1fr;
}

.TrackOverlay {
  grid-area: 1 / 1 / span 1;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow: hidden;
}

.TrackContent {
  grid-area: 1 / 1 / span 1;
  z-index: 1;
}

.TrackPreview {
  width: 100%;
  height: 70%;
}

.TrackName {
  position: absolute;
  padding: 0 8px;
  width: 100%;
  z-index: 3;
  user-select: none;
  top: 75%;
  --top-offset: 85%;
}

.TrackName h2,
.TrackName p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.TrackControls {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.TrackControls :first-child {
  border-bottom-left-radius: 1.5rem;
}

.TrackControls :last-child {
  border-bottom-right-radius: 1.5rem;
}

@media all and (max-height: 500px) and (orientation: landscape) {
}

@media all and (max-height: 600px) {
  .TrackPreview {
    height: 55%;
  }

  .TrackName {
    top: 60%;
    --top-offset: 80%;
  }
}

@media all and (max-height: 450px) {
  .TrackPreview {
    height: 50%;
  }

  .TrackName {
    top: 55%;
    --top-offset: 75%;
  }
}
