.TrackListContainer {
  width: 100%;
  padding: 0 8px;
  overflow-y: hidden;
  display: flex;
  flex: auto;
  position: relative;
  height: 300px; /* Need a fixed height or Safari on iOS doesn't render correctly */
}

.TrackListContainer::after {
  position: fixed;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: linear-gradient(
    var(--bg-0),
    var(--bg)
  ); /* linear-gradient can't be smoothly transitioned */
}

.TrackListScrollContainer {
  width: 100%;
  display: flex;
}

.TrackListWrapper {
  width: 100%;
  overflow-y: scroll;
}

.TrackList {
  width: 100%;
  padding: 1rem 0 5rem 0;
}

.TrackList > li {
  margin-bottom: 0.75rem;
}
