.NavBar {
  grid-area: nav;
  /* z-index: 2; */
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  justify-self: center;
  width: 100%;
  max-width: 415px;
  align-items: center;
}

.NavBar > * {
  flex: none;
}

@media all and (min-width: 768px) and (min-height: 500px) {
  .NavBar {
    grid-area: 1 / 2;
  }
}

@media all and (orientation: landscape) and (max-height: 500px) {
  .NavBar {
    grid-area: nav;
    align-items: flex-end;
    justify-self: flex-end;
  }
}
