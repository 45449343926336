/* Playlist Saved Screen */
.SaveActionContainer {
  background: #111111;
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.SaveRecord {
  background: url(../../images/vinyl_record@2x.jpg) no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  content: "Save Record";
  width: 90vh;
  height: 90vh;
  position: absolute;
}
