.TrackCounter {
  width: 16px;
  height: 16px;
  border: 1.5px solid white;
  background: var(--brand-primary);
  border-radius: 50%;
  font-size: 9px;
  color: white;
  position: absolute;
  top: 0;
  right: -4px;
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  user-select: none;
}

@media all and (min-width: 415px) {
  .TrackCounter {
    width: 20px;
    height: 20px;
    font-size: 12px;
    border: 2px solid white;
  }
}
