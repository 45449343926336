.TrackStackContainer {
  width: 100%;
  flex: auto;
  max-width: 415px;
  padding: 0 8px;
  z-index: 2;
  margin-top: 24px;
  position: relative;
  display: flex;
}

.TrackStackRecordContainer {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 24px;
  z-index: -1;
  pointer-events: none;
  user-select: none;
}

.TrackStackRecord {
  transform-origin: 49.5% 50.5%;
  object-fit: contain;
  animation: spinning-record 1.6s infinite linear;
  width: 100%;
}

@keyframes spinning-record {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media all and (max-height: 500px) and (orientation: landscape) {
  .TrackStackRecord {
    width: 75%;
  }

  .TrackStackContainer {
    grid-area: cards;
    height: 100%;
    padding: 44px 8px 16px 8px;
    width: 100%;
    margin: 0;
  }
}

@media all and (min-width: 360px) and (orientation: portrait) {
  .TrackStackContainer {
    padding: 0 16px;
    margin-top: 32px;
  }
}

@media all and (min-height: 812px) {
  .TrackStackContainer {
    margin: 48px 0;
  }
}

.TrackStack {
  width: 100%;
  flex: auto;
  display: grid;
  grid-template: 1fr / 1fr;
}
