.Settings {
  z-index: 20;
  width: 100%;
  max-width: 640px;
  height: 100%;
  max-height: 860px;
  margin: 0 auto 0 auto;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--text);
  padding: 24px 8px;
  border-radius: 32px;
  overflow: hidden;
  pointer-events: all;
}

.Settings h1 {
  text-align: left;
}

.Settings h2 {
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 0.75rem;
  opacity: 0.6;
  font-family: sans-serif;
}

.Settings .Btn {
  flex: none;
}

.SettingsContainer {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex: auto;
  flex-direction: column;
}

.SettingsHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.SettingsGroup {
  width: 100%;
  margin-bottom: 44px;
}

.SettingsList {
  border-top: 1px solid var(--input);
}

.Settings p {
  font-family: sans-serif;
}

.InfoListItem {
  cursor: pointer;
}

.InfoList {
  display: flex;
  flex-direction: column;
}

@media all and (min-width: 360px) {
  .Settings {
    padding: 24px 16px;
  }
}

@media all and (min-width: 768px) {
  .Settings {
    grid-area: 1 / 2;
  }
}
