.Playlist {
  z-index: 4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / span 2;
  pointer-events: none;
  overflow: hidden;
}

.Playlist > * {
  pointer-events: all;
}

.PlaylistAction {
  flex: none;
  height: 44px;
  width: 100%;
  padding: 0 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 1;
  pointer-events: none;
}

.PlaylistAction[isopen="true"] {
  background: var(--bg);
}

.PlaylistAction > * {
  pointer-events: all;
}

.PlaylistInput {
  height: 32px;
  flex: none;
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--input);
  transition: width 0.15s ease-in-out, border-radius 0.15s ease-in-out,
    background 0.15s ease-in;
  user-select: none;
}

.PlaylistInput[isopen="true"] {
  width: 100%;
}

.PlaylistInput[isopen="false"] {
  width: 32px;
  border-radius: 16px;
}

.PlaylistExpand {
  visibility: visible;
  display: flex;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.PlaylistExpand .inner {
  background: #f9cf00;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PlaylistName {
  width: 100%;
  background: none;
  border: none;
  font-size: 0.875rem;
  font-weight: var(--bold);
  padding: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text);
}

.PlaylistDropdown {
  margin-right: 8px;
  height: 44px;
  width: 44px;
  position: absolute;
  right: 0;
}

@media all and (min-width: 360px) {
  .PlaylistAction {
    height: 50px;
  }
}

@media all and (min-width: 768px) and (min-height: 500px) {
  .Playlist {
    grid-area: 1 / 1 / span 2 / span 1;
  }

  .PlaylistInput[isopen="false"] {
    width: 100%;
    border-radius: 8px;
  }

  .PlaylistDropdown,
  .PlaylistExpand {
    visibility: hidden;
    display: none;
  }

  .PlaylistAction[isopen="true"] {
    background: none;
  }
}

@media all and (max-height: 500px) and (orientation: landscape) {
  .PlaylistInput[isopen="false"] {
    margin-right: auto;
  }
}
